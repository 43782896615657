.custom-flex {
  align-items: center;
  max-width: 1400px;
  margin: auto;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 32px;
    padding-left: 8px;
    padding-right: 8px;
    .container {
      padding: 0;
      display: flex;
      justify-content: center;
    }
  }

  .dummyIphone {
    @media screen and (max-width: 767px) {
      width: 380px;
    }
    @media screen and (max-width: 425px) {
      width: 300px;
    }
  }
}

#marqueeContainer {
  .marquee_h2 {
    margin: 0 1.25rem 1.5rem;
    font-size: 40px;
    letter-spacing: 2px;
    font-family: serif;
    color: #096c6c;
    z-index: 10;
  
    @media screen and (max-width: 425px) {
      font-size: 30px;
      margin: 0 1rem 1rem;
    }
  }
  @media screen and (max-width: 767px) {
    .heading {
      padding: 0 10px;
      font-size: 24px;
    }
    .marqueeBox {
      padding: 5px;

      .reviewCard {
        width: 14rem;
        padding: 0.5rem;

        .items-center {
          img {
            width: 50px;
            height: 40px;
          }
          .flex-col {
            figcaption {
              font-size: 0.8rem;
            }
          }
        }
        blockquote {
          padding-top: 0.5rem;
          font-size: 0.8rem;
        }
      }
    }
  }
}

.feature_showcase_header {
  font-size: 40px;
  letter-spacing: 2px;
  font-family: serif;
  color: #096c6c;
  @media screen and (max-width: 425px) {
    font-size: 30px;
  }
}
.feature_showcase_mutiple_box {
  @media screen and (max-width: 425px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.feature_showcase_description {
  font-size: 35px;
  letter-spacing: 2px;
  font-family: serif;
  color: #096c6c;
  @media screen and (max-width: 767px) {
    padding: 0 1rem;
  }
  @media screen and (max-width: 425px) {
    font-size: 24px;
  }
}

.provider_banner {
  border-radius: 50px;
  font-family: serif;
  margin: 2rem 2rem;
  cursor: pointer;
  color: white;
  background-color: #096c6c;
  font-size: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 500px;
  letter-spacing: 2px;
  position: relative;
  z-index: 1;
  padding: 2rem;

  @media screen and (max-width: 767px) {
    font-size: 30px;
    height: auto;
    margin: 1rem 0px;
    padding: 1rem;

  }
  @media screen and (max-width: 425px) {
    font-size: 20px;
    height: auto;
    margin: 0 15px;
    padding: 1.5rem;
  }
}

.provider_banner_inner {
  padding-top: 2rem;
  border-radius: 30px;
  width: 100%; /* Takes full width of the parent */
  height: calc(100% - 4rem); /* Adjusts height considering padding in parent */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds subtle shadow for depth */
  overflow: hidden; /* Ensures carousel content doesn’t overflow */
  box-sizing: border-box; /* Include padding in height/width calculation */

  .banner-carousel {
    border-radius: 30px; /* Keep it slightly smaller for visual harmony */
    height: 100%; /* Ensure it takes the full height of its parent */
    width: 100%; /* Ensure it scales with the parent container */
    position: relative;
    overflow: hidden; /* Prevent content overflow */
    background: #ccfbf1; /* Soft background color */
    margin: 0; /* Remove additional margins to fit well inside the parent */
    z-index: 1; /* Ensure it's on the correct layer */
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 375px) {
      .banner{
        h1{
          font-size: 1rem;
        }
      }
    }

  }

  @media screen and (max-width: 767px) {
    height: 150px;
    padding-top: 1.5rem;
  }
  @media screen and (max-width: 425px) {
    font-size: 20px;
    margin: 0 15px;
  }
}



.appLink {
  gap: 20px;
  margin-top: 30px;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    flex-direction: column;
  }
}

/* @media (max-width: 768px) {
  .provider_banner_inner {
    border-radius: 15px; 
    margin-top: 10px; 
    padding: 0.75rem; 
    height: auto; 
    flex-direction: column; 
  }
}

@media (max-width: 480px) {
  .provider_banner_inner {
    border-radius: 10px; 
    margin-top: 5px; 
    padding: 0.5rem; 
    height: auto; 
    flex-wrap: wrap; 
    overflow-y: auto;
  }
} */
