.navWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 0 4rem;
  margin: auto;
  max-width: 1400px;

  .navbarItem {
    a {
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      color: #204a4c;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    padding-left: 5px;
    padding-right: 32px;
  }

  @media screen and (max-width: 374px) {
    padding-left: 1rem;
    padding-right: 1rem;
    .logoAndText {
      .logo {
        height: 70px;
        width: 65px;
      }
    }
  }
}

.user-profile-avatar {
  border: 3px solid #204a4c; /* Light border */
  border-radius: 50%;
}

.user-profile-avatar img {
  width: 48px !important; /* Reduced size */
  height: 48px !important; /* Reduced size */
  padding: 2px;
  border-radius: 50%;
  object-fit: cover; /* Ensures the image scales properly */
}

.user-content {
  min-width: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #e5e5e5; /* Solid white background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for better visibility */
  border: 1px solid #e5e5e5; /* Light border */
}

.user-card {
  display: flex;
  align-items: center;
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for better visibility */
  border: 1px solid #e5e5e5; /* Light border */
}

.user-card-inner {
  border-radius: 50%;
  border: 3px solid #204a4c; /* Light border */
}

.user-avatar {
  width: 28px !important; /* Reduced size */
  height: 28px !important; /* Reduced size */
  border-radius: 50%;
  padding: 1px;
  object-fit: cover; /* Ensures the image scales properly */
}

.user-name {
  font-size: 15px;
  font-weight: 700;
  margin-left: 10px;
  color: #096c6c;
}

.user-option {
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.user-option-text {
  align-content: center;
  margin-left: 8px;
  font-weight: 700;
}

.user-option-text-dialog {
  margin-top: 1px;
  margin-left: 8px;
  font-size: smaller;
  font-weight: 700;
}

.user-option:hover {
  background: white;
  border-radius: 8px;
  color: #096c6c;
}
