.privacy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .privacy-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    text-align: left;

    h2 {
      color: #555;
      margin-top: 20px;
      font-size: 1.5rem;
    }
  }
  
  h1 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;  /* Increase the size */
    font-weight: bold;  /* Make it bolder */
  }
  
  
  
  p, ul {
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 100px; /* Adjust the logo size */
    height: auto; /* Maintain aspect ratio */
  }