.pro-header {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 0 4rem;
  padding-right: 2.8rem;

  /* #counterContainer {
    @media (max-width: 425px) {
      display: none;
    }
  } */
}

.profile-location {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
}
.details-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.cover-image {
  height: 320px;
  overflow: hidden;
  position: relative;
  margin: 0.5rem 2rem;
  border-radius: 25px;
}

.cover-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-section {
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 24px;
  padding: 0 2rem;
  display: flex;
  align-items: flex-start;
  margin-top: -100px;
  position: relative;
  z-index: 10;
}

.profile-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid white;
  background: white;
}

.profile-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.pro-name {
  display: flex;
  align-items: center;
  gap: 15px;
  text-transform: capitalize;
  color: #096c6c;
  margin: 0;
  font-size: 2rem;
}

.profile-title {
  font-size: 20px;
  text-transform: capitalize;
}

.profile-title,
.profile-location {
  color: #000000;
  margin: 0.25rem 0;
  text-transform: capitalize;
}

.navigation {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  gap: 1rem;
  border-top: 1px solid #096c6c38;
  margin-top: 14px;
}

/* Mobile View */
@media (max-width: 768px) {
  .navigation {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
}

.nav-button {
  font-size: 1rem;
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #000000;
  font-weight: 600;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.nav-button.active {
  color: #096c6c;
  border-bottom: 2px solid #096c6c;
}

/* Mobile View Buttons */
@media (max-width: 768px) {
  .pro-header{
    padding: 0;

  }
  .nav-button {
    font-size: 0.9rem;
    font-weight: bold;
    flex: 1;
    text-align: center;
  }
}

.stats-container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  max-width: 600px;
  margin: 14px 4px;
  box-shadow: 0 0 4px 4px #096c6c1c;
  @media (max-width: 768px) {
    justify-content: space-around;
    .content-section{
      align-items: center;
      .value{
        font-size: 18px;
      }
      .account-creation{
        font-size: 18px;
      }
    }
  }
  @media (max-width: 320px) {
    .content-section{
      .value{
        font-size: 10px
      }
      .account-creation, .content-section-label{
        font-size: 10px
      }
    }
  }
}

.stat {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.icon-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #096c6c0f;
  border-radius: 50%;
}

.icon {
  font-size: 24px;
}

.separator {
  width: 2px;
  background-color: #d1d1d1;
  margin: 0 10px;
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.value {
  font-size: 24px;
  font-weight: bold;
}

.content-section-label {
  font-size: 14px;
  color: #777;
}

.account-creation {
  font-size: 16px;
  font-weight: bold;
}
