.dialog-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 425px;
    z-index: 1000;
  }
  
  .dialog-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-label {
    font-size: 14px;
    font-weight: 500;
    color: #555;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    outline: none;
    border-color: #0f766e;
    box-shadow: 0 0 0 2px rgba(15, 118, 110, 0.1);
  }
  
  .form-textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 8px;
  }
  
  .contact-trigger-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    transition: color 0.3s ease;
    height: 0;
  }

  .headerView{
    font-size: 20px;
    font-weight: bold;
    color: #032833;
  }
  
  .close-btn {
    padding: 8px 16px;
    background: #0f766e;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .close-btn:hover {
    background: #0c5a54;
  }
  
  .submit-btn {
    padding: 8px 16px;
    background: #0f766e;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .submit-btn:hover:not(:disabled) {
    background: #0c5a54;
  }
  
  .submit-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  /* Overlay styles */
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  /* Responsive styles */
  @media (max-width: 480px) {
    .dialog-content {
      width: 90%;
      padding: 16px;
    }
  
    .dialog-title {
      font-size: 20px;
    }
  
    .form-actions {
      flex-direction: column;
      gap: 8px;
    }
  
    .close-btn,
    .submit-btn {
      width: 100%;
    }
  }

  /* ... (previous styles remain unchanged) ... */

.thank-you-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  color: #0f766e;
  padding: 40px 20px;
  animation: fadeIn 0.5s ease-in-out;
}

.mail-icon {
  width: 48px;
  height: 48px;
  color: #0f766e;
  margin-bottom: 16px;
  animation: scaleIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* ... (rest of the previous styles remain unchanged) ... */