.banner-carousel {
  border-radius: 20px;
  height: 400px;
  position: relative;
  overflow: hidden;
  background: #ccfbf1;
  margin: 0 1rem 2rem;
  z-index: 1;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transform: translateX(100%); /* Start off-screen to the right */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.banner.active {
  opacity: 1;
  transform: translateX(0); /* Center the active banner */
  z-index: 2; /* Ensure the active banner is above others */
}

.banner.prev {
  opacity: 0;
  transform: translateX(-100%); /* Exit to the left */
  z-index: 1;
}

.banner h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-family: serif;
  letter-spacing: 2px;
}

.banner p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-family: serif;
  letter-spacing: 2px;
}

/* CSS changes */
.banner .highlight {
  font-size: 1.5rem; 
  color: #096c6c;
  font-weight: bold;
  font-family: serif;
  letter-spacing: 1px;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .banner-carousel {
    height: 200px; /* Further reduce height for smaller devices */
    margin: 0 0 1rem;
    border-radius: 15px;
  }

  .banner h1 {
    font-size: 1.5rem; /* Adjusted for better readability */
    letter-spacing: 0.5px;
  }

  .banner p {
    font-size: 0.875rem; /* Further adjust text size */
    letter-spacing: 0.5px;
  }

  .banner .highlight {
    font-size: 1.25rem; /* Smaller highlight size */
    letter-spacing: 0.5px;
  }
}