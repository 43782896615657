/* styles/ImageCarousel.css */
.image-carousel {
    position: relative;
    width: 100%;
    height: 200px;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .carousel-button.left {
    left: 10px;
  }
  
  .carousel-button.right {
    right: 10px;
  }