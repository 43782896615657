.styleAboutSection {
  font-family: arial, sans-serif !important;
  border-collapse: collapse;
  width: 100%;
  background: white;
  min-height: 600px;

  td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }

  td:first-child {
    min-width: 200px;
  }

}

.language{
  display: flex;
  flex-direction: column;
}
