.login-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  background-color: #ffffff;
}

.login-card {
  background: white;
  padding: 2rem;
  width: 100%;
}

.login-card-logo{
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.login-card h1 {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.subtitle {
  color: #666;
  margin-bottom: 2rem;
}

.mobile-input-group {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.country-code {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1rem;
  background-color: #f5f5f5;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-weight: 500;
  color: #1a1a1a;
  white-space: nowrap;
}

.flag {
  width: 24px;
  height: 16px;
  border-radius: 2px;
  object-fit: cover;
}

.otp-section p {
  color: #666;
  font-size: 0.875rem;
}

.otp-input-group {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
}

.otp-input {
  width: 3rem;
  height: 3rem;
  font-size: 1.25rem;
  text-align: center;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background: white;
  transition: all 0.2s ease;
}

.otp-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 1px #2563eb;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.resend-button {
  background: none;
  border: none;
  font-size: 0.875rem;
  margin-top: 1rem;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}

.resend-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.submit-button {
  width: 100%;
  color: white;
  font-weight: 500;
  height: 2.75rem;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error-message {
  color: #dc2626;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.success-message {
  color: #16a34a;
  font-size: 0.875rem;
  margin-top: 1rem;
  text-align: center;
}

.order-dialog{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.order-dialog-text{
  font-size: large;
  font-weight: 700;
}

.order-dialog-btn{
  background-color: #096c6c;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 15px;
}

.order-dialog-btn :hover{
  background-color: #064747;
  color: #ffffff;
}

@media (max-width: 640px) {
  .login-card {
    padding: 1.5rem;
  }

  .login-card h1 {
    font-size: 1.5rem;
  }
}


