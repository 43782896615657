.sticky-bar {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  z-index: 11;
  transition: opacity 0.3s ease-in-out;
}

.sticky-bar.hidden {
  opacity: 0;
  pointer-events: none;
}

.sticky-bar.visible {
  opacity: 1;
}

.sticky-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  max-width: 1200px;
  width: 100%;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 1px 5px #096c6ca8;
}

/* Image Section */
.sticky-bar-image img {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  /* object-fit: cover; */
}

/* Info Section */
.sticky-bar-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.item-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 0;
  text-transform: capitalize;
}

.item-price {
  font-size: 14px;
  color: #555;
  margin: 5px 0 0;
}

/* Button Section */
.cart-button {
  background-color: #096c6c;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.cart-button:hover {
  background-color: #064747;
}

/* General Mobile Adjustments */
@media (max-width: 768px) {
  .sticky-bar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    max-width: 1200px;
    width: 100%;
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 14px;
    box-shadow: 0 1px 5px #096c6ca8;
  }
}