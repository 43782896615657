.category-section {
    padding: 4rem 2rem;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    position: relative;
    z-index: 10;
  }
  
  .category-section.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .section-title {
    color: #096c6c;
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    font-family: serif;
    letter-spacing: 2px;
  }
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .category-card {
    border: 1px solid #096c6c;
    background: white;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .category-card:hover {
    transform: translateY(-5px);
  }
  
  .category-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #096c6c;
    font-family: serif;
    letter-spacing: 2px;
  }
  
  .category-card p {
    color: #000000;
    margin-bottom: 1.5rem;
    font-family: serif;
    letter-spacing: 2px;
  }
  
  .category-card ul {
    list-style-type: none;
    padding: 0;
  }
  
  .category-card li {
    padding: 0.5rem 0;
    color: #096c6c;
    position: relative;
    padding-left: 1.5rem;
  }
  
  .category-card li:before {
    top: 7px;
    font-size: 16px;
    content: "->";
    position: absolute;
    left: 0;
    color: #000000;
  }
  
  @media (max-width: 768px) {
    .categories-grid {
      grid-template-columns: 1fr;
    }
  }
  
  