.service-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.service-card:hover {
  transform: translateY(-4px);
}

.service-content {
  padding: 1.5rem;
}

.serviceName {
  margin: 0;
  color: #333;
  font-size: 1.25rem;
  gap: 15px;
  align-items: center;
  display: flex;
  text-transform: capitalize;
}

.serviceDescription {
  color: #000000;
  margin: 0.5rem 0 1rem;
}

.serviceType {
  padding: 1rem 0 0.5rem;
  gap: 5px;
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  color: black;
}

.servicePlace {
  gap: 5px;
  display: flex;
  align-items: center;
}

.service-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #096c6c;
}

.book-button {
  background: #096c6c;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.book-button:hover {
  background: #075858;
}

.book-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.addonsSection {
  padding: 1.5rem;
  border-top: 1px solid #096c6c38;
}

.addon-disclaimer {
  font-size: 0.9rem;
  color: #000000;
  margin-bottom: 1rem;
}

.addon-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 10px;
  border-bottom: 1px solid #096c6c38;
}

.secondBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thirdBlock {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.addon-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 1rem;
}

.addon-info {
  flex: 1;
}

.addonName {
  margin: 0;
  color: #096c6c;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem;
}

.addonDescription {
  margin: 0.25rem 0;
  color: #000000;
  font-size: 0.9rem;
}

.addon-price {
  font-weight: bold;
  color: #096c6c;
}

.addon-button {
  background: #096c6c;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  min-width: 80px;
}

.addon-button:hover {
  background: #075858;
}

.addon-button:disabled {
  min-width: 80px;
  background: #ccc;
  cursor: not-allowed;
}
