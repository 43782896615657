.service-booking {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 10;
  gap: 2rem;
  min-height: auto;
  box-sizing: border-box;
}

.service-booking.expanded {
  height: auto; /* Full height when extra content is added */
  /* overflow-y: auto; Enables scrolling */
}

.service-booking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ccfbf1;
  border-radius: 0 0 16px 16px;
}

.header-button{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  cursor: pointer;
  padding: 0.2rem 0.6rem;
  font-size: 16px;
  color: #096c6c;
  border: 2px solid #096c6c;
  border-radius: 16px;
  path {
    fill: #096c6c;
  }
}

.error-message {
  color: #dc2626;
  font-size: 0.875rem;
}

.cart {
  min-height: 600px;
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  .cart-header {
    align-items: center;
    display: inline-flex;
    gap: 10px;
  }
}
.service-section {
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
}

.service-section,
.addons-section,
.related-addons {
  margin-bottom: 0.5rem;
}

.service-card-cart {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #096c6c;
  border-radius: 0.5rem;
}

.addonsSectionCart {
  border-radius: 0.5rem;
  border: 1px solid #096c6c;
}

.addon-card {
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-bottom-color: #efefef;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantity-controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  background: none;
  cursor: pointer;
}

.incrementAddon:hover {
  background-color: #00800087;
}
.decrementAddon:hover {
  background-color: #ff0000b5;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}
.delete-button:hover {
  color: red;
}

.total {
  display: flex;
  font-size: 1.875rem;
  justify-content: flex-end;
  gap: 1rem;
  font-weight: bolder;
  margin: 15px 0;
  margin-right: 1rem;
}

.related-addons-grid {
  display: grid;
  grid-template-columns: 50% auto;
  gap: 1rem;
}

.related-addon-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #096c6c;
  border-radius: 0.5rem;
}

.addon-button-cart {
  background: #096c6c;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  min-width: 80px;
}

.addon-button-cart:hover {
  background: #064747;
  color: white;
}

.booking-section {
  display: grid;
  gap: 2rem;
  margin: 2rem 0;
}

.time-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 0.5rem;
  margin-top: 1rem;
}


/* Sticky Footer Bar */
.sticky-bar {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  transition: opacity 0.3s ease-in-out;
}

.sticky-bar.hidden {
  opacity: 0;
  pointer-events: none;
}

.sticky-bar.visible {
  opacity: 1;
}

.sticky-bar-content {
  background-color: #fff;
  max-width: 1200px;
  width: 100%;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 1px 5px rgba(9, 108, 108, 0.66);
}

/* Image Section */
.sticky-bar-image img {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  object-fit: cover;
}

/* Info Section */
.sticky-bar-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.item-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 0;
  text-transform: capitalize;
}

.item-price {
  font-size: 14px;
  color: #555;
  margin: 5px 0 0;
}

/* Button Section */
.cart-button {
  background-color: #096c6c;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease-in-out;
}

.cart-button:hover {
  background-color: #064747;
}


.time-slot-button {
  width: 100%;
}

.timeAndAddress {
  display: flex;
  gap: 2rem;
}

.useraddress {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  button {
    max-width: 250px;
  }
}
.userEnteraddress {
  min-height: 400px;
}
.enterLocation {
  display: grid;
  gap: 1rem;
  grid-template-columns: 70% auto;
  input {
    border: 3px solid #096c6c;
  }
  input:focus {
    outline: none;
  }
}
.useCurrentBtn {
  background: #096c6c;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.useCurrentBtn:hover {
  background: #064747;
  color: white;
}

.searchList {
  li {
    margin: 20px 0;
    font-size: 1rem;
  }
}

.InvalidLocation {
  gap: 2rem;
  flex-direction: column;
  display: flex;
  margin: 2rem 0;
}

@media (min-width: 768px) {
  .booking-section {
    grid-template-columns: 1fr 1fr;
  }
}

/* General Mobile Adjustments */
@media (max-width: 768px) {
  .service-booking {
    padding: 0 0.5rem;
  }

  .service-booking-header {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }

  .header-button{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    cursor: pointer;
    padding: 0.2rem 0.6rem;
    font-size: 13px;
    font-weight: bold;
    color: #096c6c;
    border: 2px solid #096c6c;
    border-radius: 16px;
    path {
      fill: #096c6c;
    }
  }
  .cart {
    padding: 1rem;
    min-height: auto;
    box-shadow: none;
  }

  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }

  .service-section,
  .addons-section,
  .related-addons {
    margin-bottom: 1rem;
  }

  .service-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .service-card-cart {
    font-weight: bold;
  }

  .addonsSectionCart,
  .addon-card {
    padding: 0.5rem;
    border-radius: 0.25rem;
  }

  .addon-card {
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
  }

  .quantity-controls button {
    width: 1.5rem;
    height: 1.5rem;
  }

  .total {
    font-weight: bold;
    font-size: xx-large;
    justify-content: right;
    text-align: center;
  }

  .related-addons-grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .related-addon-card {
    flex-direction: column;
    align-items: stretch;
  }

  .addon-button-cart {
    width: 100%;
    text-align: center;
    padding: 0.75rem 1rem;
  }

  .booking-section {
    grid-template-columns: 1fr;
    margin: 1rem 0;
    gap: 1rem;
  }

  .time-grid {
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .sticky-bar-image img {
    width: 50px;
    height: 50px;
  }

  .sticky-bar-info {
    margin-left: 0;
    text-align: center;
  }

  .cart-button {
    width: 100%;
  }

  .timeAndAddress {
    flex-direction: column;
    gap: 1rem;
  }

  .useraddress button {
    width: 100%;
    max-width: none;
  }

  .userEnteraddress {
    min-height: auto;
  }

  .enterLocation {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .searchList li {
    margin: 10px 0;
    font-size: 0.9rem;
  }

  .InvalidLocation {
    margin: 1rem 0;
  }
}

